<template>
	<div class="shell">
		<div class="shell-lf">
			<div class="detail">
				<div class="detail-img">
					<img :src="musicInfo.image" />
				</div>
				<div class="detail-songname">{{ musicInfo.title }}</div>
				<div class="detail-name">{{ musicInfo.creator }}</div>
			</div>
		</div>
		<div class="shell-rg">
			<!-- v-if="recommend != 'sea'" -->
			<div class="empower_area" v-if="demoType == 2">
				<div class="empower-lf">
					<div class="empower-p">{{$t("buytype")}}</div>
					<div class="area">
						<div class="area_item" v-for="(item, index) in BuyList" :key="index">
							<el-button class="item-currentbuy" :class="{ 'active': currentbuy == index }"
								@click="Buybtn(index, item.is_beat)">{{ item.name }}</el-button>
						</div>
					</div>
				</div>
				<div class="empower-rg">
					<div class="money">￥{{ Math.ceil(price) }}</div>
				</div>
			</div>
			<div class="empower_area" v-if="demoType == 2">
				<div class="empower-lf">
					<div class="empower-p">{{$t("recommend.authorizedTerritory")}}</div>
					<div class="area">
						<div class="area_item" v-for="(item, index) in AreaList" :key="index">
							<el-button class="item-areabuy" :class="{ 'active': Areabuy == index }"
								@click="Areabtn(item, index)">{{ item.name }}</el-button>
						</div>
					</div>
				</div>

			</div>
			<!-- <div class="empower_area" v-if="demoType == 1">
				<div class="empower-lf">
					<div class="empower-p">购买类型</div>
					<div class="area">
						<div class="area_item" v-for="(item, index) in BuyList" :key="index">
							<el-button class="item-currentbuy" :class="{ 'active': currentbuy == index }"
								@click="Buybtn(index, item.id)">{{ item.name }}</el-button>
						</div>
					</div>
				</div>
				<div class="empower-rg">
					<div class="money">￥{{ price }}</div>
				</div>
			</div> -->
			<div class="empower_area" v-if="demoType == 1">
				<div class="empower-lf">
					<div class="empower-p">{{$t("recommend.authorizedTerritory")}}</div>
					<div class="area">
						<div class="area_item" v-for="(item, index) in AreaList" :key="index">
							<el-button class="item-areabuy" :class="{ 'active': Areabuy == index }"
								@click="Areabtn(item, index)">{{ item.name }}</el-button>
						</div>
					</div>
				</div>
				<div class="empower-rg">
					<div class="money">￥{{ Math.ceil(price) }}</div>
				</div>
			</div>
			<div class="empower_type_sea" v-if="demoType == 2">
				<div class="type_title">{{$t("recommend.authorizedType")}}</div>
				<div class="type_box">
					<div class="type_item" v-for="(item, index) in AreaList[Areabuy].typeList" :key="index">
						<div class="type_btn"
							:class="{ 'active': Typebuy == index }">
							<div class="title-box">
								<div class="title-lf">
									<div>{{ item.name }}</div>
									<div>{{ item.en_name }}</div>
								</div>
								<div class="title-btm">
									<div class="title-btm-list" v-for="(typeitem, index) in item.details" :key="index">{{ typeitem.name }}</div>
								</div>
								<!-- <div class="title-lf">{{ item.en_name }}</div> -->
								<!-- <Icon name="guanluquan" color="#3370FF" width="16" height="16"></Icon> -->
								
							</div>
							<!-- <div class="pubilc-box" v-if="Areabuy == 0">
								<div class="pubilc" v-for="(item, index) in mainlandTypeList" :key="index">
									{{ item.name }}</div>
							</div>
							<div class="pubilc-box" v-if="Areabuy == 1">
								<div class="pubilc" v-for="(item, index) in worldTypeList" :key="index">{{ item.name }}
								</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>
			<div class="empower_type" v-if="demoType == 1">
				<div class="type_title">{{$t("recommend.authorizedType")}}</div>
				<div class="type_box">
					<div class="type_item" v-for="(item, index) in AreaList[Areabuy].typeList" :key="index">
						<div class="type_btn" @click="Typebtn(item,index)"
							:class="{ 'active': Typebuy == index }">
							<div class="title-box">
								<div class="title-lf">
									<div>{{ item.name }}</div>
									<div>{{ item.en_name }}</div>
								</div>
								<div class="title-btm">
									<div class="title-btm-list" v-for="(typeitem, index) in item.details" :key="index">{{ typeitem.name }}</div>
								</div>
								<!-- <div class="title-lf">{{ item.en_name }}</div> -->
								<!-- <Icon name="guanluquan" color="#3370FF" width="16" height="16"></Icon> -->
								
							</div>
							<!-- <div class="pubilc-box" v-if="Areabuy == 0">
								<div class="pubilc" v-for="(item, index) in mainlandTypeList" :key="index">
									{{ item.name }}</div>
							</div>
							<div class="pubilc-box" v-if="Areabuy == 1">
								<div class="pubilc" v-for="(item, index) in worldTypeList" :key="index">{{ item.name }}
								</div>
							</div> -->
						</div>
					</div>
				</div>
			</div>
			<div class="empower_area">
				<div class="empower-lf">
					<div class="empower-p">{{$t("recommend.authorizedyear")}}</div>
					<div class="area">
						<div class="area_item" v-for="(item, index) in TimeList" :key="index">
							<el-button class="item-areabuy" :class="{ 'active': Timebuy == index }"
								@click="Timebtn(item, index)">{{ item.name }}</el-button>
						</div>
					</div>
				</div>
			</div>
			<div class="empower_area">
				<div class="empower-lf">
					<div class="empower-quan">{{$t("recommend.selectcouppon")}}</div>
					<div class="area">
						<el-form :inline="true" :model="formInline" class="demo-form-inline">
							<el-form-item class="discount">
								<!-- <el-select v-model="formInline.region" placeholder="活动区域">
									<el-option label="区域一" value="shanghai"></el-option>
									<el-option label="区域二" value="beijing"></el-option>
								</el-select> -->
								<el-select
								v-if="
								  Areabuy != -1 &&
								  Typebuy != -1 &&
								  Timebuy != -1
								"
								  v-model="discountValue"
								  @change="changeCoupon"
								  :placeholder="$t('recommend.selectcouppon')"
								   popper-class="reset-popper-bg-color"
								>
								  <el-option
								    v-for="item in couponLists"
								    :key="item.id"
								    :label="
								      item.couponName +
								      ': ' + $t('recommend.man') +
								      parseInt(item.minPrice) +
								      '-' +
								      parseInt(item.couponPrice)
								    "
								    :value="item.id"
								  >
								  </el-option>
								</el-select>
								<el-select
								  v-else
								  disabled
								  v-model="discountValue"
								  :placeholder="$t('recommend.selectcouppon')"
								>
								  <el-option
								    v-for="item in couponLists"
								    :key="item.id"
								    :label="
								      item.couponName +
								      ': ' + $t('recommend.man') +
								      item.minPrice +
								      '-' +
								      item.couponPrice
								    "
								    :value="item.id"
								  >
								  </el-option>
								</el-select>
							</el-form-item>

						</el-form>
						<!-- <div class="area_item" v-for="(item, index) in TimeList" :key="index">
              <el-button class="item-areabuy" :class="{ 'active': Timebuy == index }"
                @click="Timebtn(index, item.multiple)">{{ item.name }}</el-button>
            </div> -->
					</div>
				</div>
			</div>


		</div>
		<!-- <span slot="footer" class="dialog-footer">
      <el-button @click="centerDialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="centerDialogVisible = false">立即支付</el-button>
    </span> -->
	</div>
</template>

<script>
	import {
		priceArr,logo,couponList
	} from "@/api/Home";
	export default {
		props: {
			musicInfo: {
				type: Object,
				default () {
					return {};
				},
			},
			demoType: {
				type: Number,
				default: 1,
			}
		},
		data() {
			return {
				formInline: {
					user: '',
					region: ''
				},
				price: 0,
				outerVisible: false,
				innerVisible: false,
				currentbuy: -1,
				Areabuy: 0,
				Timebuy: -1,
				Typebuy: 0,
				BuyList: [{
						name: this.$t("arrangement"),
						id: 1,
						is_beat: 0
					},
					{
						name: this.$t("compose"),
						id: 2,
						is_beat: 1
					}
				],
				AreaList: [

				],
				mainlandTypeList: [], //大陆
				worldTypeList: [], //全球
				price_arr: [],
				arrname: [],
				TimeList: [],
				recommend: '',
				newprice: '',
				couponLists: "",//优惠券列表
				couponId: "",//优惠券id
				discountValue: "",//优惠券value
				discountPrice: "",
				lockPrice: '',//锁定价格
			};
		},
		components: {},
		computed: {},
		created() {
			this.BuyMusicList()
			// this.getLogoImg()
			this.recommend = this.musicInfo.recommend
		},
		mounted() {
			let dataCoupon = {};
			  // dataCoupon.recommend = 1;
			  let dataList = [];
			  couponList(dataCoupon).then((res) => {
			    console.log("优惠券:", res);
			    for (let i = 0; i < res.data.length; i++) {
			      if (res.data[i].status == 0) {
			        dataList.push(res.data[i]);
			      }
			    }
			    this.couponLists = dataList;
			  });
			  console.log(this.couponLists,'this.couponLists')
			  // this.cartDialogList = item;
			  // this.buyMusic = item.musicId;
			  // this.cartDialog = true;
		},
		watch: {},
		methods: {
			changeCoupon(val) {
			  console.log(val,'changeCoupon');
			
			  // this.couponLists
			  for (let i = 0; i < this.couponLists.length; i++) {
			    if (val == this.couponLists[i].id) {
			      if (this.cartprice < this.couponLists[i].minPrice) {
			        this.discountPrice = 0;
			        this.discountValue = "";
			        this.$message({
			          type: "error",
			          message: this.$t("coupponnotuse"),
			        });
			      } else {
			        this.discountPrice = this.couponLists[i].couponPrice;
					console.log(this.discountPrice,'this.discountPrice')
					this.price = this.price - this.discountPrice
					let discountObj = {
						discountPrice:this.discountPrice,
						discountValue:this.discountValue
					}
					this.$emit('getdiscountPrice',discountObj)
					this.$emit('getPrice',Math.ceil(this.price))
			        this.couponId = val;
			      }
			    }
			  }
			},
			// // 获取logo图片
			// getLogoImg(){
			// 	logo()
			// 		.then((res) => {
			// 			console.log(res, '获取logo图片');
			// 			this.payInfo.codeImg = res
			// 		})
			// 		.catch((err) => {
			// 			// console.log(err, '获取banner失败');
			// 		});
			// },
			// 立即购买列表
			BuyMusicList() {
				priceArr({
						price: this.musicInfo.price,
						musicId: this.musicInfo.musicId
					})
					.then((res) => {
						console.log(res, 'priceArr')
						// this.lockPrice = res.data.lockPrice;
						// console.log(this.lockPrice,'BuyMusicList')
						// this.lockPrice = 100
						// this.$emit('getlockPrice',this.lockPrice)
						if (res.data.lockPrice) {
						  this.lockPrice = res.data.lockPrice;
						  console.log(this.lockPrice,'BuyMusicList')
						  this.$emit('getlockPrice',this.lockPrice)
						}
						//时间列表
						this.TimeList = res.data.duration
						// console.log(this.TimeList, 'this.TimeList')

						//地区列表
						let arr = res.data.price_arr
						for (var key in arr) {
							// console.log(key, arr[key])
							let keyObj = arr[key]
							this.AreaList.push({
								id: key,
								name: arr[key]
							})

						}
						let arr2 = this.AreaList.map(item => {
							// console.log(item, 'itemmmmmm')
							let arr3 = []
							for (var key in item.name) {
								// console.log(key, '4444444444444')
								arr3.unshift({
									name: key,
									price: item.name[key].price,
									details: JSON.parse(item.name[key].details),
									en_name: item.name[key].en_name
								})
							}
							// console.log(arr3, '1111111111111')
							return {
								name: item.id,
								typeList: arr3
							}
						})
						this.AreaList = arr2
						// console.log(this.AreaList, arr2, 'AreaList')

						//权限列表
						let arrname = []
						for (var key in this.AreaList[this.Areabuy].name) {
							arrname.push({
								id: key,
								name: this.AreaList[this.Areabuy].name[key]
							})
							// console.log(this.AreaList, 'AreaList')
						}
						// this.price = this.AreaList[this.Areabuy].typeList[this.Typebuy].price
						this.$emit('authType',this.AreaList[this.Areabuy].typeList[this.Typebuy])
						this.$emit('areaType',this.AreaList[this.Areabuy].name)
						// console.log(this.Areabuy,this.Typebuy,this.Timebuy, 'mounted')
						// this.mainlandTypeList = JSON.parse(arrname[this.Areabuy].name.details) //大陆
						// this.worldTypeList = JSON.parse(arrname[this.Areabuy].name.details) //全球

						// this.arrname = arrname
					})
					.catch((err) => {

					});
			},
			Areabtn(item, index) {
				// console.log(item.name, 'Areabtn')
				this.Areabuy = index
				
				if(this.Timebuy != -1){
					this.price = Math.ceil(item.typeList[this.Typebuy].price * this.TimeList[this.Timebuy].multiple)
				}
				this.$emit('getPrice',this.price)
				this.$emit('areaType',item.name)
				
				
				// this.price = item.typeList[this.Typebuy].price
				// this.price = this.AreaList[this.Areabuy].typeList[this.Typebuy].price
				// console.log(this.Areabuy,this.Typebuy,this.Timebuy, 'Areabtn')
				// this.AreaList = []
				// this.BuyMusicList();
				// console.log(this.Areabuy, '我是什么')
			},
			Timebtn(item, index) {
				console.log(item,'Timebtn')
				this.Timebuy = index
				console.log(this.lockPrice,'this.lockPrice')
				
				if(Math.ceil(this.AreaList[this.Areabuy].typeList[this.Typebuy].price * item.multiple) > this.lockPrice){
					this.price = Math.ceil(this.AreaList[this.Areabuy].typeList[this.Typebuy].price * item.multiple) - this.lockPrice
				}else {
					this.price = 0
				}
				this.$emit('getPrice',this.price)
				this.$emit('changeTime',item.id)
				// this.price = this.AreaList[this.Areabuy].typeList[this.Typebuy].price
				// console.log(this.Areabuy,this.Typebuy,this.Timebuy, 'Timebtn')
				// console.log(i)
				// this.newprice = this.price * i
				// // this.price = i.name.price
				// console.log(this.newprice, '新鲜出炉的价格是多少嘞 ')
				// this.Timebuy = index
			},
			Buybtn(index, i) {
				console.log(i)
				this.currentbuy = index
				this.$emit('buyType',i)
				
			},
			Typebtn(item, index) {
				console.log(item,'Typebtn')
				this.Typebuy = index
				this.$emit('authType',item)
				if(this.Timebuy != -1){
					this.price = Math.ceil(item.price * this.TimeList[this.Timebuy].multiple)
				}
				
				this.$emit('getPrice',this.price)
				
				// this.price = item.price
				// console.log(this.Areabuy,this.Typebuy,this.Timebuy, 'Typebtn')
				// console.log(i)
				// this.price = i.name.price
				// console.log(this.price, '当前什么什么权的价格')
				// this.Typebuy = index
			},
		},
	};
</script>

<style scoped>
	
	.shell {
		width: 100%;
		/* width: 986px; */
		height: 60vh;
		overflow-y: scroll;
		margin: auto;
		box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.06);
		border-radius: 4px;
		display: flex;
		
		/* align-items: center; */
	}
	.shell::-webkit-scrollbar {
		/*隐藏滚轮*/
		display: none;
	}
</style>
<style scoped lang="less">
	* {
		::-webkit-scrollbar {
			/*隐藏滚轮*/
			display: none;
		}
	}
	::v-deep .el-select .el-input__inner {
		background-color: @recommendMusicianListBGColor;
		color: @truebuttonTextColor;
	}

	::v-deep .el-select-dropdown__list {
		list-style: none;
		padding: 0.375rem 0;
		background-color: @recommendMusicianListBGColor  !important;
		margin: 0;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
	}

	::v-deep .el-select-dropdown__item.selected {
		color: @recommendMusicianListBGColor  !important;
		font-weight: 700;
	}

	::v-deep .el-select-dropdown__item.hover,
	.el-select-dropdown__item:hover {
		background-color: @recommendMusicianListBGColor  !important;
	}

	* {
		::-webkit-scrollbar {
			/*隐藏滚轮*/
			display: none;
		}
	}

	.shell-lf {
		width: 250px;
		// height: 600px;

		.detail {
			width: 220px;
			height: 296px;
			background: #f5f5f5;
			border-radius: 6px;
			margin-left: 24px;
			margin-top: 24px;

			.detail-img {
				width: 220px;
				height: 220px;
				border-radius: 6px 6px 0px 0px;

				img {
					width: 220px;
					height: 220px;
					border-radius: 6px 6px 0px 0px;
				}
			}

			.detail-songname {
				width: 100%;
				height: 22px;
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #222426;
				line-height: 22px;
				margin-left: 12px;
				margin-top: 15px;
			}

			.detail-name {
				width: 100%;
				height: 17px;
				font-size: 12px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: #666666;
				line-height: 17px;
				margin-left: 12px;
				margin-top: 7px;
			}
		}
	}

	.shell-rg {
		width: 650px;
		// height: 600px;

		.empower_area {
			width: 650px;
			height: 90px;
			display: flex;
			justify-content: space-between;
			margin-top: 5px;

			.empower-lf {
				width: 436px;
				height: 100px;

				.empower-quan {
					width: 80px;
					height: 20px;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: @recommendMusicianListTextColor;
					line-height: 20px;
					margin-left: 24px;
					margin-top: 10px;
				}

				.empower-p {
					// width: 64px;
					height: 20px;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: @recommendMusicianListTextColor;
					line-height: 20px;
					margin-left: 24px;
					margin-top: 10px;
				}

				.area {
					display: flex;


					.discount {
						width: 200px;
						height: 30px;
						margin-left: 24px;
						margin-top: 7px;
					}

					.area_item {
						width: 100px;
						height: 30px;
						margin-left: 24px;
						margin-top: 7px;


						.item-currentbuy.active {
							color: @truebuttonTextColor;
							background-color: @truebuttonBgColor;
							border-color: @truebuttonBorderBgColor;
						}

						.item-areabuy.active {
							color: @truebuttonTextColor;
							background-color: @truebuttonBgColor;
							border-color: @truebuttonBorderBgColor;
						}

						.item-areabuy {
							color: @buttonTextColor;
							background-color: @buttonBgColor;
							// background-color: @popupCancelBgColor;
							border-color: @buttonBorderBgColor;
						}

						.item-currentbuy {
							color: @buttonTextColor;
							background-color: @buttonBgColor;
							// background-color: @popupCancelBgColor;
							border-color: @buttonBorderBgColor;
						}

						// 悬浮
						.item-btn:hover {
							color: @truebuttonTextColor;
							background-color: @truebuttonBgColor;
							border-color: @truebuttonBorderBgColor;
						}

						// 点击
						.item-btn:focus {
							color: @truebuttonTextColor;
							background-color: @truebuttonBgColor;
							border-color: @truebuttonBorderBgColor;
						}
					}
				}
			}

			.empower-rg {
				// width: 100px;
				height: 50px;
				white-space: nowrap;

				.money {
					width: 125px;
					height: 56px;
					font-size: 30px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #3370ff;
					line-height: 42px;
				}
			}
		}

		.empower_type {
			// width: 650px;
			width: 100%;
			height: 320px;
		
			.type_title {
				// width: 64px;
				height: 20px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: @recommendMusicianListTextColor;
				line-height: 20px;
				margin-left: 24px;
				padding-bottom: 5px;
			}
		
			.type_box {
				width: 100%;
				height: 300px;
				overflow-y: scroll;
				color: @recommendMusicianListTextColor;
				margin-left: 15px;
		
				.type_item {
					margin-top: 16px;
					
		
					.title-box {
						display: flex;
						flex-direction: column;
						padding: 0 20px;
		
						.title-lf {
							margin-right: 10px;
							display: flex;
							line-height: 30px;
						}
						.title-btm{
							display: flex;
							flex-wrap: wrap;
							line-height: 30px;
							.title-btm-list {
								margin-right: 20px;
							}
						}
					}
		
					.pubilc-box {
						display: flex;
						margin-top: 10px;
						flex-wrap: wrap;
		
						.pubilc {
							width: 33.33%;
							height: 20px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-top: 10px;
						}
					}
		
					.type_btn.active {
						color: @truebuttonTextColor;
						background-color: @truebuttonBgColor;
						border-color: @truebuttonBorderBgColor;
					}
		
					.type_btn {
						width: 99%;
						height: 110px;
						color: @buttonTextColor;
						background-color: @buttonBgColor;
						// border-color: @buttonBorderBgColor;
						border: 1px solid @buttonBorderBgColor;
						border-radius: 6px;
						display: flex;
						align-items: center;
		
						.catalogue {
							width: 165px;
							// height: 30px;
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							// color: @recommendMusicianListTextColor;
							line-height: 20px;
						}
					}
		
					// 悬浮
					.type_btn:hover {
						color: @truebuttonTextColor;
						background-color: @truebuttonBgColor;
						border-color: @truebuttonBorderBgColor;
					}
		
					// 点击
					.type_btn:focus {
						color: @truebuttonTextColor;
						background-color: @truebuttonBgColor;
						border-color: @truebuttonBorderBgColor;
					}
				}
			}
		}
		.empower_type_sea {
			// width: 650px;
			width: 100%;
			height: 320px;
		
			.type_title {
				width: 64px;
				height: 20px;
				font-size: 14px;
				font-family: PingFangSC-Regular, PingFang SC;
				font-weight: 400;
				color: @recommendMusicianListTextColor;
				line-height: 20px;
				margin-left: 24px;
				padding-bottom: 5px;
			}
		
			.type_box {
				width: 100%;
				height: 300px;
				overflow-y: scroll;
				color: @recommendMusicianListTextColor;
				margin-left: 15px;
		
				.type_item {
					margin-top: 16px;
					
		
					.title-box {
						display: flex;
						flex-direction: column;
						padding: 0 20px;
		
						.title-lf {
							margin-right: 10px;
							display: flex;
							line-height: 30px;
						}
						.title-btm{
							display: flex;
							flex-wrap: wrap;
							line-height: 30px;
							.title-btm-list {
								margin-right: 20px;
							}
						}
					}
		
					.pubilc-box {
						display: flex;
						margin-top: 10px;
						flex-wrap: wrap;
		
						.pubilc {
							width: 33.33%;
							height: 20px;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-top: 10px;
						}
					}
		
					// .type_btn.active {
					// 	color: @truebuttonTextColor;
					// 	background-color: @truebuttonBgColor;
					// 	border-color: @truebuttonBorderBgColor;
					// }
		
					.type_btn {
						width: 99%;
						height: 110px;
						color: @buttonTextColor;
						background-color: @buttonBgColor;
						// border-color: @buttonBorderBgColor;
						border: 1px solid @buttonBorderBgColor;
						border-radius: 6px;
						display: flex;
						align-items: center;
		
						.catalogue {
							width: 165px;
							// height: 30px;
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							// color: @recommendMusicianListTextColor;
							line-height: 20px;
						}
					}
		
		// 			// 悬浮
		// 			.type_btn:hover {
		// 				color: @truebuttonTextColor;
		// 				background-color: @truebuttonBgColor;
		// 				border-color: @truebuttonBorderBgColor;
		// 			}
		
		// 			// 点击
		// 			.type_btn:focus {
		// 				color: @truebuttonTextColor;
		// 				background-color: @truebuttonBgColor;
		// 				border-color: @truebuttonBorderBgColor;
		// 			}
				}
			}
		}
	}
</style>
