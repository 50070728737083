<template>
	<div class="main">
		<div class="left">
			<div class="avatar">
				<img :src="orderInfo.avatar"/>
			</div>
			<div class="desc">
				<div class="name">{{orderInfo.name}}</div>
				<div v-if="type == 0" class="authorized">
					<div class="territory">
						{{$t('recommend.authorizedTerritory')}}：{{orderInfo.authorizedTerritory}}
					</div>
					<div class="type">
						{{$t('recommend.authorizedType')}}：{{orderInfo.authorizationType}}
					</div>
				</div>
				<div v-if="type == 1" class="lock-time">
					<div class="lock-title">
						{{$t('recommend.lockTime')}}
					</div>
					<div class="lock-content">
						<div @click="changeCurrent(index,item)" :class="{active:currentId == index}" class="time-list" v-for="(item,index) in orderInfo.timeList" :key="index">
							{{item.day}}{{$t('recommend.day')}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- <div class="middle"></div> -->
		<div class="right">
			<div class="order-sn">
				{{ $t('recommend.orderSn') }}：{{orderInfo.orderSn ? orderInfo.orderSn : '0'}}
			</div>
			<div class="order-money">
				<div class="money-icon">￥</div>
				<div class="money-num">{{orderInfo.orderMoney ? Math.ceil(orderInfo.orderMoney) : 0}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		props:{
			orderInfo: {
				type: Object,
				default(){
					return {}
				}
			},
			type: {
				type: Number,
				default: 0,
			}
			
		},
		data(){
			return {
				currentId: -1,//锁定时间当前选中
			}
		},
		methods:{
			// 切换锁定时间
			changeCurrent(index,item){
				console.log(item,'changeCurrent')
				this.currentId = index
				this.$emit('changeDay', item.day)
			}
		}
	}
</script>

<style lang="less" scoped>
	.main{
		// width: 938px;
		height: 142px;
		background: @recommendMusicianListBGColor;
		border-radius: 6px;
		display: flex;
		justify-content: space-between;
		.left{
			display: flex;
			width:70%;
			padding: 16px 0 16px 16px;
			.avatar{
				img{
					width: 110px;
					height: 110px;
					border-radius: 6px;
					margin-right: 16px;
				}
			}
			.desc{
				width:100%;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.name{
					font-size: 16px;
				}
				.authorized{
					width:100%;
					color: @headerTextColor;
					.territory{
						margin-bottom: 8px;
					}
				}
				.lock-time{
					color: @headerTextColor;
					.lock-title{
						margin-bottom: 8px;
						color: @recommendMusicianListTextColor;
						font-size: 14px;
					}
					.lock-content{
						display: flex;
						cursor: pointer;
						.time-list{
							width: 76px;
							height: 36px;
							background: rgba(255,255,255,0.08);
							// background: red;
							border-radius: 4px;
							border: 1px solid @popupLockTimeBorderColor;
							display: flex;
							align-items: center;
							justify-content: center;
							margin-right: 16px;
							color: @recommendMusicianListTextColor;
							&.active{
								border: 1px solid @popupCancelBorderColor;
								color: @popupCancelTextColor;
								background-color:@buttonBgColor;
								// background: red;
							}
							&:hover{
								border: 1px solid @popupCancelBorderColor;
								color: @popupCancelTextColor;
								background-color:@buttonBgColor;
							}
						}
						
					}
				}
			}
			
		}
		.right{
			padding: 16px 16px 16px 0;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			font-size: 16px;
			.order-sn{
				color: @headerTextColor;
			}
			.order-money{
				display: flex;
				justify-content: flex-end;
				color: #3370FF;
				font-family: Roadgeek2005Engschrift;
				.money-icon{
					font-size: 30px;
					font-weight: 500;
				}
				.money-num{
					font-size: 40px;
					font-weight: 500;
					
				}
			}
		}
	}
</style>